<template>
  <div class="user-profile">
    <div class="user-profile__avatar-box">
      <img :src="user.avatar" alt="Фото" class="user-profile__avatar" />
    </div>
    <div class="user-profile__info">
      <div class="user-profile__fullname">{{ user.fullname }}</div>
      <div class="user-profile__details">
        <span class="user-profile__position">{{
          $t(`master.${user.position}`)
        }}</span>
        <span class="user-profile__id">{{ user.id }}</span>
      </div>
    </div>
    <div class="user-profile__btn-box">
      <ButtonLogout @click.native="openModal" />
    </div>
    <Popup :dialog.sync="modal">
      <div class="popup__logout-icon">
        <svg-icon name="logout"></svg-icon>
      </div>
      <Heading level="4" class="popup__title">Вихід із системи</Heading>
      <div class="popup__subtitle">
        Ви впевнені, що хочете вийти із системи?
      </div>
      <div class="popup__buttons-box">
        <ButtonBase
          color="white"
          size="md-bg"
          class="popup__btn"
          @click.native="closeModal"
        >
          <span class="new-ticket__btn-titles">
            {{ $t('popup.cancel') }}
          </span>
        </ButtonBase>
        <ButtonBase
          color="blue"
          size="md-bg"
          class="popup__btn"
          @click.native="logoutHandler"
        >
          <template v-if="!loadingLogoutBtn">
            {{ $t('popup.confirm') }}
          </template>
          <Loader v-else color="white" />
        </ButtonBase>
      </div>
    </Popup>
  </div>
</template>

<script>
import Heading from '@/elements/Heading/Heading.vue';

import { mapGetters } from 'vuex';
import ButtonLogout from '@/elements/Buttons/ButtonLogout.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

import Popup from '@/elements/Popup/Popup.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    Heading,
    ButtonLogout,
    ButtonBase,
    Popup,
    Loader,
  },
  data() {
    return {
      user: {
        id: '#',
        fullname: '',
        position: 'operator_service_area',
        avatar: '/images/user/avatar.svg',
      },
      modal: false,
      loadingLogoutBtn: false,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  mounted() {
    this.user.fullname = this.getUser?.full_name;
    this.user.id += this.getUser?.number;
  },

  methods: {
    openModal() {
      this.modal = true;
    },

    closeModal() {
      this.modal = false;
    },
    async logoutHandler() {
      this.closeModal();
      this.loadingLogoutBtn = true;
      await this.$store.dispatch('userLogout');
      this.loadingLogoutBtn = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.user-profile
  display: flex
  align-items: center

.user-profile__btn-box
  font-size: 0

.user-profile__avatar
  display: block
  margin-right: 16px

.user-profile__info
  align-self: flex-start
  margin-right: 40px

  @media screen and (max-width: 1199px)
    margin-right: 32px

.user-profile__fullname
  font-weight: bold
  font-size: 14px
  line-height: 17px
  color: var(--primary-color-dark-blue)
  margin-bottom: 2px

.user-profile__position,
.user-profile__id
  font-weight: 600
  font-size: 12px
  line-height: 14px
  opacity: 0.5

.user-profile__id
  margin-left: 6px
</style>

<template>
  <main class="main">
    <Header />
    <Content />
    <Footer />

    <Popup :dialog.sync="modal" is-persistent>
      <svg-icon name="rotate-device" class="popup__icon"></svg-icon>
      <Heading level="4">
        {{ $t('popup.rotate_device') }}
      </Heading>
      <div class="popup__subtitle">
        {{ $t('popup.rotate_device_info') }}
      </div>
    </Popup>
    <Notification
      v-if="notification"
      :type="notification.type"
      :title="notification.title"
      :text="notification.text"
    />
  </main>
</template>

<script>
import Header from '@/components/Layout/Header/Header.vue';
import Content from '@/components/Layout/Content/Content.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';

import Popup from '@/elements/Popup/Popup.vue';
import Notification from '@/elements/Notification/Notification.vue';
import Heading from '@/elements/Heading/Heading.vue';
export default {
  components: {
    Header,
    Content,
    Footer,
    Popup,
    Notification,
    Heading,
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    notification() {
      return this.$store.getters.getNotification;
    },
  },
  mounted() {
    if (document.documentElement.clientWidth <= 990) {
      this.modal = true;
    }
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (document.documentElement.clientWidth <= 990) {
          this.modal = true;
        } else {
          this.modal = false;
        }
      }, 100);
    });
  },
};
</script>
<style lang="sass" scoped>
.main
  min-height: 100vh
  display: flex
  flex-direction: column
</style>

<template>
  <div class="content">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="sass" scoped>
.content
  background-color: var(--primary-color-light-grey-1)
  flex-grow: 1
</style>

<template>
  <span class="logo">
    <img src="@/assets/images/logo/app-logo.svg" alt="ETQueue" />
  </span>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.logo,
.logo img
  display: block
</style>
